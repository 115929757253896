@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


.background {
    background: linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('img/bg.jpeg') center/cover no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: sticky;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

.banner {
    display: flex;
    text-align:center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 200px;
    width: 90%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 50%;
    color: white;
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url('img/mixer.jpeg') center/cover no-repeat;
}


.content {
    position: relative;
    height: 100%;
    box-sizing: content-box;
}
.text-container{
    text-align:center;
    width: 90%;
    margin: auto;
    margin-bottom: 2%;
    height: 50%;
    color: white;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: content-box;
    background-color: rgba(32, 28, 36, 0.5);
    border-radius: 20px;
}
.textblock{
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
}

.slogan{
    margin-top: 2%;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
}

.subquestion{
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
}

.contactinfo{
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    margin-bottom: 2%;
}


.text-container-grid{
    text-align:center;
    width: 100%;
    margin: auto;
    margin-bottom: 2%;
    height: 100%;
    color: white;
    position: relative;
    padding-right: 0%;
    justify-content: space-evenly;
}

.image-container {
    display: flex;
    position: relative;
    width: 100%;
  }
.image-container img {
    max-width: 90%;
    height: auto;
  }
  .carousel-container {
    display: flex;
    position: relative;
    max-width: auto;
    margin: 5%;
    max-height: 720px;
    justify-content: center;
    align-self: center;
    margin-bottom: 2%;
  }
.carousel-container img {
    position: relative;
    justify-content: center;
    object-fit:scale-down;
    width: auto;
    max-height: 720px;
    border-radius: 20px !important; 
}

.carousel .slide { display: flex; justify-content: center; } 

 .carousel {
    margin-left: auto;
    border-radius: 20px;
    margin-bottom: 2%;
 }
 @media (max-width: 720px) {
    .carousel {
        max-height: 100%;
    }
    .carousel-container {
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }
    
    .rowDiv{
        display: flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    }
    
    .bg-text {
        display: grid;
        height: 50%;
        width: 65%;
        place-items: center;
        background-color: rgba(32, 28, 36, 0.5);
        border-radius: 20px;
        margin-left: 20%;
        margin-right: 2%;
        position: relative;
        box-sizing: border-box;
    }
    